<template>
	<div v-if="report.isLoaded" class="portal loaded content intro bg-white mb-5 maw-xl">
		<h1>My Report Portal</h1>
		<hr class="mt-35 mb-4 w-100" />
		<show-at :breakpoints="{medium: 900}" breakpoint="mediumAndAbove">
			<b-container class="full-width" fluid>
				<b-row>
					<b-col cols="8">
						<h2 class="mb-4">Message Board</h2>
						<chat />
					</b-col>
					<b-col cols="4">
						<h2 class="mb-4">Case Details</h2>
						<case-details />
					</b-col>
				</b-row>
			</b-container>
		</show-at>
		<hide-at :breakpoints="{medium: 900}" breakpoint="mediumAndAbove">
			<b-tabs>
				<b-tab title="Message Board">
					<chat />
				</b-tab>
				<b-tab title="Case Details">
					<case-details />
				</b-tab>
			</b-tabs>
		</hide-at>
	</div>
</template>

<script>
import { Chat, CaseDetails } from "@/components";
import { BTab, BTabs } from 'bootstrap-vue'
import {showAt, hideAt} from 'vue-breakpoints'
export default {
	name: 'MyPortal',
	components: {
		Chat, CaseDetails, BTab, BTabs, hideAt, showAt
	},
	computed: {
		report() {
			return this.$store.state.report
		}
	},
	// watch: {
	// 	report(val) {
	// 		console.log('report changed', val);
	// 	}
	// },
	created() {
		if (!this.$store.state.report.isLoaded) {
			this.$http.get(this.$store.getters.apiDisclosurePath + 'report-details').then(response => {
			// this.$http.get('/json/report-details.json').then(response => {
				this.$store.commit('set', {report: {...response.data, isLoaded: true}})
			})
		}
		this.$store.commit('set', {activityTimer: true})
	},
	metaInfo: {
		title: 'My Portal',
	},
}
</script>

<style lang="scss">

.portal {
	@include media-breakpoint-down(xs) {
		&.content.intro {
			margin-bottom: 0 !important;
			padding-bottom: 0 !important;
		}
		.tab-pane > div {
			padding-bottom: 2rem;
		}
	}
	hr {
		border-color: $light;
	}
	.container {
		@extend %full-width;
	}
	textarea {
		&::placeholder {
			font-style: italic;
		}
		&:focus {
			border-color: $gray-400;
		}
	}
	.rounded-pill {
		padding: 0.5rem 1.5rem;
	}
}

.download-report{
	border-top: 1px solid black;
	border-bottom: 1px solid black;
}
</style>
